import React from 'react'

const LinkCard = ({ item, index, text, courseFlag = false, setter }) => {
  let tags = []
  if (item.tags) {
    tags = item.tags.split(',')
  }
  return (
    <li
      key={index}
      className={
        courseFlag
          ? 'wrapper style5'
          : [index % 2 === 0 ? 'wrapper style2' : 'wrapper style1']
      }
    >
      {courseFlag ? (
        <h2 className="blogTitle hs h2">
          {' '}
          <b>
            {' '}
            <span role="img" aria-label="star">
              ⭐
            </span>{' '}
            COURSE{' '}
            <span role="img" aria-label="star">
              ⭐
            </span>{' '}
          </b>{' '}
        </h2>
      ) : null}
      <h2 className="blogTitle hs h2"> {item.title} </h2>
      <p
        className="blog-description p"
        style={tags[0] ? { marginBottom: 8 } : null}
      >
        {' '}
        {item.description}{' '}
      </p>
      {tags &&
        tags.map((tag, index) => (
          <a
            className={`blog-tag ${index > 0 ? `second-tag` : null}`}
            onClick={() => setter(tag)}
            key={`tag-${tag}`}
          >
            {tag}
          </a>
        ))}
      <a
        href={item.url}
        className="button a"
        aria-label={`Watch ${item.title} now`}
      >
        {' '}
        {text}{' '}
      </a>
    </li>
  )
}

export default LinkCard
