import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import LinkCard from '../components/LinkCard'

const LessonsPage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH Lessons'

  return (
    <Layout>
      <Helmet title={pageTitle} />
      <section id="banner">
        <div id="banner" className="inner">
          <header>
            <h2 className="hs h2">LESSONS</h2>
          </header>
        </div>
      </section>
      <section id="about" className="main style1">
        <section className="wrapper style4 container">
          <ul className="posts no-bullets ul">
            {data.allCoursesYaml.edges.map(({ node }, index) => (
              <LinkCard
                item={node}
                index={`course-${index}`}
                text="Watch Now"
                courseFlag={true}
              />
            ))}
          </ul>
          <ul className="posts no-bullets ul">
            {data.allLessonsYaml.edges.map(({ node }, index) => (
              <LinkCard item={node} index={index} text="Watch Now" />
            ))}
          </ul>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allLessonsYaml {
      edges {
        node {
          title
          description
          url
        }
      }
    }
    allCoursesYaml {
      edges {
        node {
          title
          description
          url
        }
      }
    }
  }
`

export default LessonsPage
